import { Container, Text, Heading, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { merge } from 'lodash';
import type { ElementType } from 'react';
import type { ParseKeys } from 'i18next';
import type { ThemeColor } from 'types/color';
import { Section, type SectionProps } from '../Section';
import { SwipeableSections } from '../SwipeableSections';
import CarBargainIllustration from '../../assets/car-bargain.svg';
import CarSafetyIllustration from '../../assets/car-safety.svg';
import CarScamIllustration from '../../assets/car-scam.svg';
import styles from './StepsSection.module.scss';

type STEP = {
  id: 'scam' | 'validation' | 'bargain';
  titleKey: ParseKeys<'home'>;
  descriptionKey: ParseKeys<'home'>;
  Illustration: ElementType;
};

type StepsSectionProps = {
  color?: ThemeColor;
  sectionVariant?: SectionProps['variant'];
  title?: string;
  subtitleShown?: boolean;
  overwriteStepsContent?: {
    [key in STEP['id']]?: Partial<Pick<STEP, 'titleKey' | 'descriptionKey'>>;
  };
};

const STEPS: STEP[] = [
  {
    id: 'scam',
    titleKey: 'stepsSection.steps.mistakes.title',
    descriptionKey: 'stepsSection.steps.mistakes.description',
    Illustration: CarScamIllustration,
  },
  {
    id: 'validation',
    titleKey: 'stepsSection.steps.time.title',
    descriptionKey: 'stepsSection.steps.time.description',
    Illustration: CarSafetyIllustration,
  },
  {
    id: 'bargain',
    titleKey: 'stepsSection.steps.negotiation.title',
    descriptionKey: 'stepsSection.steps.negotiation.description',
    Illustration: CarBargainIllustration,
  },
];

const StepsSection = ({
  color,
  overwriteStepsContent,
  sectionVariant = 'l',
  subtitleShown = false,
  title,
}: StepsSectionProps) => {
  const { t } = useTranslation('home');

  const mergeStepsWithOverwrites = () =>
    STEPS.map((defaultStep) => {
      const overwriteStep = overwriteStepsContent?.[defaultStep.id];

      return merge({}, defaultStep, overwriteStep);
    });

  const steps = mergeStepsWithOverwrites();
  const swipeableSteps = steps.map(({ Illustration, descriptionKey, titleKey }) => ({
    title: t(titleKey),
    description: t(descriptionKey),
    Component: <Illustration original />,
  }));

  return (
    <Section color={color} variant={sectionVariant}>
      <Container>
        <Stack gap={{ mobileUp: 5, desktopUp: 8 }} crossAxisAlign="stretch">
          <Stack gap={2} crossAxisAlign="center">
            <Heading variant="l" as="h2" align="center">
              {title || t('stepsSection.title')}
            </Heading>

            {subtitleShown && (
              <Text variant="l" align="center">
                {t('stepsSection.subtitle')}
              </Text>
            )}
          </Stack>

          <Stack className={styles.desktopSteps} gap={4}>
            {steps.map(({ Illustration, descriptionKey, id, titleKey }) => (
              <Stack gap={4} as="section" key={id} className={styles.card}>
                <Illustration
                  original
                  role="img"
                  aria-label={t(titleKey)}
                  className={styles.illustration}
                />

                <Stack gap={2} crossAxisAlign="center">
                  <Heading variant="s" as="h3" className={styles.title}>
                    {t(titleKey)}
                  </Heading>

                  <Text variant="m">{t(descriptionKey)}</Text>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <SwipeableSections steps={swipeableSteps} className={styles.mobileSteps} />
        </Stack>
      </Container>
    </Section>
  );
};

export type { StepsSectionProps };
export { StepsSection };
